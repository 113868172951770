/* Copyright © Bankers Healthcare Group, Inc.  All rights reserved. */
import moment from 'moment';

const dateFilters = {
  toDate(date) {
    return moment(date).format('MM/DD/YYYY');
  },
  toUTCDate(date) {
    return moment(date).utc().format('MM/DD/YYYY');
  },
};

export default dateFilters;
